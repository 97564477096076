.cell_reply div {
	display: -webkit-box;
	max-width: 40vw;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 15vw;
	overflow-y: scroll;
}

.cell_originalReply div {
	display: -webkit-box;
	max-width: 40vw;
	-webkit-line-clamp: 10;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.cell_question div {
	display: -webkit-box;
	max-width: 10vw;
	-webkit-line-clamp: 10;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 15vw;
	overflow-y: scroll;
}

.cell_originalQuestion div {
	display: -webkit-box;
	max-width: 10vw;
	-webkit-line-clamp: 10;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.cell_formattedQuestion div {
	display: -webkit-box;
	max-width: 10vw;
	-webkit-line-clamp: 10;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.cell_translatedQuestion div {
	display: -webkit-box;
	max-width: 10vw;
	-webkit-line-clamp: 10;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.pageContentContainer {
	flex-direction: row;
}

.pageContentContainer table tr div {
	width: auto !important;
}

.rightPannelContainer {
	display: flex;
	margin-top: 2.5rem;
	position: relative;
	/* width: 100%; */
	min-width: 30vw;
}

.rightPannel {
	position: sticky;
	height: 98vh;
	width: 100%;
	top: 1vh;
}

.pannelCardHeader {
	overflow: auto;
	white-space: pre-wrap;
}

.exchangeMessageThread {
	cursor: pointer;
}

.exchangeMessageThread:hover {
	background-color: #f5f5f5;
}
