.cell_firstContent div {
	display: -webkit-box;
	max-width: 40vw;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 15vw;
	overflowy: scroll;
}

.cell_question div {
	display: -webkit-box;
	min-width: 10vw;
	overflow: hidden;
	text-overflow: ellipsis;
}

.resultsImportTable {
	width: 100%;
	text-align: left;
	border-spacing: 1rem 0.5rem;
	padding: 5px;
	background-color: #f1f1f1;
	border-radius: 10px;
	margin-top: 10px;
}

.importFullCell {
	width: 100%;
}
